import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Button from '../button'

const FontStyleIco = {color:'#46BAE8', marginRight: '.1rem'};

const Box = styled.div`
  position: relative;
  padding: 2rem 1rem;
  margin: 1.5rem 0;
  border: 5px solid ${({theme}) => theme.colors.mainBlue};
  box-shadow: 4px 4px 0px 0px rgba(232,222,46,1), inset 4px 4px 0px 0px rgba(232,222,46,1);
  background: rgba(232,222,46, .1);

  :nth-child(2) {
    background: rgba(70,186,232, .1);
  }
  :nth-child(3) {
    background: rgba(31,120,156, .2);
  }

  ${({theme}) => theme.media.tablet} {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 3rem 2rem 2rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 2rem;
    border: 7px solid ${({theme}) => theme.colors.mainBlue};
  }
`;

const TopBox = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const BottomBox = styled.div`
  text-align: start;
  margin-bottom: 1rem;

  ${({theme}) => theme.media.tablet} {
    flex-grow: 1;
  }
`;

const PriceBox = styled.div`
  text-align: center;
  margin-top: 2rem;

  ${({theme}) => theme.media.laptop} {
    margin-top: .5rem;
  }
`;

const H3 = styled.h3`
  font-size: 1.6rem;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);

  ${({theme}) => theme.media.laptopXL} {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.9rem;
  }
`;

const H5 = styled.h5`
  font-size: 1.1rem;
  font-weight: ${({isPrice}) => isPrice ? '300' : '500'};
  color: ${({theme}) => theme.colors.mainBlue};
`;

const H4 = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mainBlue};
`;

const Text = styled.p`
  margin: .5rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 1.1rem;
  }
`;


const BoxSubscription = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuerySubscription {
      site {
        siteMetadata {
          subscriptions {
            dataSub
            id
            name
            price
            purpose
            user
            to
            whereto
          }
        }
      }
    }
  `)
  
  return (
    <>
      {data.site.siteMetadata.subscriptions.map(subscription => (
      
        <Box key={subscription.id}>
          <TopBox>
            <H3>{subscription.name}</H3>
            <H5>{subscription.purpose}</H5>
          </TopBox>
          <BottomBox>
            {subscription.dataSub.map((item, index) => (
              <Text key={index}><FontAwesomeIcon icon={faCheckSquare} style={FontStyleIco} /> {item}</Text>
            ))}
          </BottomBox>
          <PriceBox>
            <H4>{subscription.price}</H4>
            <H5 isPrice>{subscription.user}</H5>
            {subscription.whereto ? (
              <Button 
              as={AniLink}
              fade to={subscription.to}
              onClick={e => {
                e.preventDefault()
                trackCustomEvent({
                  category: "Subscription Button",
                  action: "Click",
                  label: "Przycisk kupna Abonamentu",
                })
              }}
              >Zamów</Button> ) : (
                <Button 
                  as='a'
                  target="_blank"
                  rel="noopener noreferrer"
                  href={subscription.to}
                >Zamów</Button>
            )}
          </PriceBox>
        </Box>
      ))}
    </>
  )
}

export default BoxSubscription