import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HeaderWrapper = styled.section`
  position: relative;
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    padding-bottom: 4rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding-bottom: 6rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding-bottom: 9rem;
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  padding: 3rem 0 4rem;
  background: ${({theme}) => theme.colors.grayBlue};

  ${({theme}) => theme.media.tablet} {
    padding: 2rem 0 4rem;
  }

  ${({theme}) => theme.media.tabletXL} {
    margin-top: 4rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 3.5rem 0 5rem;
  }
`;

const TextBox = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    padding: 0 3rem;
    width: 80%;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 0 3rem 0 6rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 0 6rem;
  }

  ${({theme}) => theme.media.desktop} {
    width: 60%;
    padding: 3rem 15rem;
  }
  ${({theme}) => theme.media.desktopXL} {
    max-width: 2100px;
    padding: 3rem 25rem;
  }
`;

const H1 = styled.h1`
  position: relative;
  color: ${({theme}) => theme.colors.mainWhite};
  padding: 1rem;
  font-size: calc(4.31818vw + 28.18182px);
  line-height: 1.3;

  ${({theme}) => theme.media.tablet} {
    font-size: calc(4.31818vw + 25.18182px);
  }

  ${({theme}) => theme.media.laptop} {
    line-height: 1.1;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 5rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 1rem;
    width: 5rem;
    height: 5px;
    background: ${({theme}) => theme.colors.mainYellow};
  }
`;

const ImageBox = styled.div`
  display: none;
  mix-blend-mode: hard-light;

  ${({theme}) => theme.media.tablet} {
    display: block;
    position: absolute;
    top: 10%;
    right: 0;
    width: 25%;
    z-index: 2;
  }

  ${({theme}) => theme.media.laptop} {
    width: 22%;
    right: 5%;
    top: 5%;
  }

  ${({theme}) => theme.media.desktop} {
    right: 10%;
  }

  ${({theme}) => theme.media.desktopXL} {
    width: 19%;
    right: 15%;
  }
`;


const HeaderOffer = () => {
  const data = useStaticQuery(graphql`
    query {
      rocketImage: file(relativePath: { eq: "rocket.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <InnerWrapper>

        <TextBox>
          <H1>Wybierz Swój abonament</H1>
        </TextBox>

      </InnerWrapper>

      <ImageBox>
        <Img fluid={data.rocketImage.childImageSharp.fluid} alt="rakieta kosmiczna" />
      </ImageBox>

    </HeaderWrapper>
  )
}

export default HeaderOffer