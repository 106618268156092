import React from 'react'
import styled from 'styled-components'
import BoxSubscription from '../offerComponents/boxSubscription'

const SubscriptionWrapper = styled.section`
  width: 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding: 2rem;

  ${({theme}) => theme.media.tabletXL} {
    padding: 2rem 2rem 4rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 2rem 4rem 4rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 90%;
  }

  ${({theme}) => theme.media.desktop} {
    width: 80%;
    padding: 2rem 4rem 5rem 9rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    width: 70%;
    padding: 3rem 4rem 5rem 9rem;
  }
`;

const WrapperText = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    width: 65%;
  }

  ${({theme}) => theme.media.tabletXL} {
    width: 50%;
    padding: 0 3rem;
  }

  ${({theme}) => theme.media.laptop} {
    width: 50%;
  }

  ${({theme}) => theme.media.desktop} {
    width: 60%;
    padding: 0 0 0 7rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    width: 70%;
    padding: 0 0 0 17rem;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.2;
  margin-bottom: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
  }
`;

const TextTitle = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;

const Boxes = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    margin-top: 3rem;
  }
`;



const Subscription = () => {
  return (
    <SubscriptionWrapper>
      <InnerWrapper>

        <WrapperText>
          <H2>Poznaj <span>nasze plany</span> abonamentowe</H2>
          <TextTitle>Celem Komputera w chmurze jest zapewnienie łatwego, skalowalnego dostępu Tobie i twoim pracownikom do programów i usług IT.</TextTitle>
        </WrapperText>
        <Boxes>
          <BoxSubscription/>
        </Boxes>

      </InnerWrapper>
    </SubscriptionWrapper>
  )
}

export default Subscription