import React from 'react'

// import Layout from "../components/layout"
import HeaderOffer from "../components/offerComponents/headerOffer"
import Subscription from "../components/offerComponents/subscription"
import FreeTray from '../components/offerComponents/freeTray'
import Questions from '../components/offerComponents/questions'
import SEO from "../components/seo"

const OfferPage = () => {
  return (
    <>
      <SEO 
        title="Komputer i serwer w chmurze prywatnej już od 59zł/mc."
        description="Serwer w prywatnej chmurze i komputer do logowania to wszystko, czego potrzebujesz do pracy w chmurze. Zestaw do zdalnego stanowiska pracy już od 59zł."
      />

      <HeaderOffer />
      <Subscription />
      <FreeTray />
      <Questions />

    </>
    
  )
}

export default OfferPage