import styled from 'styled-components'

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  text-decoration: none;
  padding: .3rem 1.6rem;
  margin-top: 1rem;
  color: ${({theme}) => theme.colors.mainBlue};
  border: 3px solid #46BAE8;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainWhite};
`;

export default Button