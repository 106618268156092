import React, { useState } from 'react'
import styled from 'styled-components'
import {Collapse} from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareDown, faCaretSquareUp } from '@fortawesome/free-solid-svg-icons'

const FontStyle = {color:'#46BAE8', marginRight: '.4rem'};

const QuestionsWrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 2rem 0;

  ${({theme}) => theme.media.tablet} {
    padding: 3rem 2rem;

    ::after {
      position: absolute;
      z-index: -1;
      content: '';
      top: 0;
      right: 0;
      height: 70%;
      width: 40%;
      background: ${({theme}) => theme.colors.mainBlue};
    }
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding: 2rem;

  ${({theme}) => theme.media.laptop} {
    display: grid;
    grid-template-columns: 40% 1fr;
  }

  ${({theme}) => theme.media.laptopXL} {
    /* width: 90%; */
  }

  ${({theme}) => theme.media.desktop} {
    padding: 5rem;
    width: 95%;
  }
`;

const TextBox = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    width: 50%;
  }

  ${({theme}) => theme.media.tabletXL} {
    padding: 0 0 0 6rem;
  }

  ${({theme}) => theme.media.laptop} {
    width: 100%;
    transform: translateY(-50px);
    padding: 0 0 0 3.5rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 0 0 0 9rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 0 0 0 20rem;
  }
`;

const H3 = styled.h3`
  font-size: calc(2.27273vw + 14.72727px);
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.laptop} {
    font-size: 2.1rem;
  }
  ${({theme}) => theme.media.laptopXL} {
    font-size: 2.4rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 2.7rem;
  }

  span {
    font-size: calc(2.27273vw + 15.72727px);
    color: ${({theme}) => theme.colors.mainBlue};
  }
`;

const QandA = styled.div`
  position: relative;
  width: 100%;
  background: ${({theme}) => theme.colors.mainWhite};

  div.ReactCollapse--collapse {
    transition: 0.5s;
  }

  ${({theme}) => theme.media.tablet} {
    width: 85%;
    margin: 0 auto;
    padding: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    width: 100%;

    ::before {
      position: absolute;
      z-index: -1;
      content: '';
      bottom: 5%;
      left: -20%;
      height: 70%;
      width: 40%;
      background: ${({theme}) => theme.colors.mainYellow};
    }
  }

  ${({theme}) => theme.media.desktop} {
    padding: 3rem;
  }
`;

const OneQandA = styled.div`
  
`;

const TextQandA = styled.p`
  position: relative;
  font-weight: 500;
  padding: .5rem 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.mainBlue};
  cursor: pointer;

  ${({theme}) => theme.media.laptop} {
    padding: .5rem 3rem .5rem 0;

    svg {
      position: absolute;
      right: 0;
      bottom: 5px;
    }
  }

  ${({theme}) => theme.media.laptopXL} {
    font-size: 1.1rem;
    padding: .8rem 3rem .8rem 0;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.2rem;
    padding: 1rem 3rem 1rem 0;
  }
`;

const AnswerQandA = styled.p`
  font-weight: 300;
  padding: .5rem;
  background: rgba(70,186,232, .1);

  ${({theme}) => theme.media.laptopXL} {
    font-size: 1.1rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.2rem;
  }
`;


const Questions = () => {

const [isOpened1, setIsOpened1] = useState(true)
const [isOpened2, setIsOpened2] = useState(false)
const [isOpened3, setIsOpened3] = useState(false)
const [isOpened4, setIsOpened4] = useState(false)
const [isOpened5, setIsOpened5] = useState(false)
const [isOpened6, setIsOpened6] = useState(false)
const [isOpened7, setIsOpened7] = useState(false)

const openQuestions1 = () => {
  setIsOpened1(!isOpened1)

  setIsOpened2(false)
  setIsOpened3(false)
  setIsOpened4(false)
  setIsOpened5(false)
  setIsOpened6(false)
  setIsOpened7(false)
}
const openQuestions2 = () => {
  setIsOpened2(!isOpened2)

  setIsOpened1(false)
  setIsOpened3(false)
  setIsOpened4(false)
  setIsOpened5(false)
  setIsOpened6(false)
  setIsOpened7(false)
}
const openQuestions3 = () => {
  setIsOpened3(!isOpened3)

  setIsOpened1(false)
  setIsOpened2(false)
  setIsOpened4(false)
  setIsOpened5(false)
  setIsOpened6(false)
  setIsOpened7(false)
}
const openQuestions4 = () => {
  setIsOpened4(!isOpened4)

  setIsOpened1(false)
  setIsOpened2(false)
  setIsOpened3(false)
  setIsOpened5(false)
  setIsOpened6(false)
  setIsOpened7(false)
}
const openQuestions5 = () => {
  setIsOpened5(!isOpened5)

  setIsOpened1(false)
  setIsOpened2(false)
  setIsOpened3(false)
  setIsOpened4(false)
  setIsOpened6(false)
  setIsOpened7(false)
}
const openQuestions6 = () => {
  setIsOpened6(!isOpened6)

  setIsOpened1(false)
  setIsOpened2(false)
  setIsOpened3(false)
  setIsOpened4(false)
  setIsOpened5(false)
  setIsOpened7(false)
}
const openQuestions7 = () => {
  setIsOpened7(!isOpened7)

  setIsOpened1(false)
  setIsOpened2(false)
  setIsOpened3(false)
  setIsOpened4(false)
  setIsOpened5(false)
  setIsOpened6(false)
}

  return (
    <QuestionsWrapper>
      <InnerWrapper>

        <TextBox>
          <H3 ata-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000"><span>?</span> Najczęściej zadawane pytania</H3>
        </TextBox>

        <QandA >

          <OneQandA>
            <TextQandA onClick={openQuestions1}>
              {isOpened1 ? <FontAwesomeIcon icon={faCaretSquareUp} style={FontStyle} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={FontStyle} />} 
              Czym komputer w chmurze różni się od google driva czy dropboxa?</TextQandA>
            <Collapse isOpened={isOpened1}>
              <AnswerQandA>Google drive czy dropbox to dyski w chmurze, a komputer w chmurze to cały Twój komputer gdzie są zainstalowane Twoje aplikacje i programy. Różnica jest wręcz nieporównywalna.</AnswerQandA>
            </Collapse>
          </OneQandA>

          <OneQandA>
            <TextQandA onClick={openQuestions2}>
            {isOpened2 ? <FontAwesomeIcon icon={faCaretSquareUp} style={FontStyle} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={FontStyle} />}
              Moje komputery pracują bardzo wolno, czy jest na to jakieś rozwiązanie?</TextQandA>
            <Collapse isOpened={isOpened2}>
              <AnswerQandA>Aby nie wymieniać wszystkich komputerów w firmie – co wiąże się z dużymi kosztami, proponujemy nasz sprzęt komputerowy z szybkim, zdalnym komputerem w chmurze.</AnswerQandA>
            </Collapse>
          </OneQandA>

          <OneQandA>
            <TextQandA onClick={openQuestions3}>
            {isOpened3 ? <FontAwesomeIcon icon={faCaretSquareUp} style={FontStyle} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={FontStyle} />}
              Szukam rozwiązania, dzięki któremu mógłbym się uniezależnić od obecnych komputerów stacjonarnych.</TextQandA>
            <Collapse isOpened={isOpened3}>
              <AnswerQandA>Możesz przenieść swoje komputery na nasz serwer, dzięki temu będziesz mógł pracować na każdym sprzęcie w dowolnie wybranym miejscu.</AnswerQandA>
            </Collapse>
          </OneQandA>

          <OneQandA>
            <TextQandA onClick={openQuestions4}>
            {isOpened4 ? <FontAwesomeIcon icon={faCaretSquareUp} style={FontStyle} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={FontStyle} />}
               Moi pracownicy często pracują po za firmą i potrzebują niezbędnych programów w terenie.</TextQandA>
            <Collapse isOpened={isOpened4}>
              <AnswerQandA>W tym wypadku świetnie sprawdzi się system zdalny komputera w chmurze, wymagany jest jedynie dostęp do internetu.</AnswerQandA>
            </Collapse>
          </OneQandA>

          <OneQandA>
            <TextQandA onClick={openQuestions5}>
            {isOpened5 ? <FontAwesomeIcon icon={faCaretSquareUp} style={FontStyle} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={FontStyle} />}
               W mojej firmie jest kilka komputerów, jak uzytkownicy mogą wymieniać się danymi w swobodny i bezpieczny sposób?</TextQandA>
            <Collapse isOpened={isOpened5}>
              <AnswerQandA>Korzystając z komputera w chmurze otrzymasz specjalny serwer do przechowywania danych, w ramach którego gwarantujemy również bezpieczną wymianę danych.</AnswerQandA>
            </Collapse>
          </OneQandA>

          <OneQandA>
            <TextQandA onClick={openQuestions6}>
            {isOpened6 ? <FontAwesomeIcon icon={faCaretSquareUp} style={FontStyle} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={FontStyle} />}
               Ile otrzymuje miejsca w ramach komputera w chmurze</TextQandA>
            <Collapse isOpened={isOpened6}>
              <AnswerQandA>Na jednego użytkownika jest to 10GB, dla przykładu jeśli firma ma 10 pracowników jest to 100GB do wykorzystania, gdzie jeden pracownik może wykorzystać 70 GB a inny 2GB.</AnswerQandA>
            </Collapse>
          </OneQandA>

          <OneQandA>
            <TextQandA onClick={openQuestions7}>
            {isOpened7 ? <FontAwesomeIcon icon={faCaretSquareUp} style={FontStyle} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={FontStyle} />}
               Wiem, że komputer w chmurze korzysta z systemu Windows, w mojej firmie są komputery apple, czy mogę w tym wypadku pracować na e-Pulpicie?</TextQandA>
            <Collapse isOpened={isOpened7}>
              <AnswerQandA>Z komputerem w chmurze można połaczyć się z każdego urządzenia bez względu na system operacyjny.</AnswerQandA>
            </Collapse>
          </OneQandA>

        </QandA>

      </InnerWrapper>
    </QuestionsWrapper>
  )
}

export default Questions